import type { ButtonHTMLAttributes, FC, MouseEventHandler, ReactNode } from 'react'
import { twJoin } from 'tailwind-merge'

const PrimaryButton: FC<
  ButtonHTMLAttributes<HTMLButtonElement> & {
    children: ReactNode
    onClick?: MouseEventHandler<HTMLButtonElement> | undefined
    className?: string
    disabled?: boolean
    leftIcon?: ReactNode
    rightIcon?: ReactNode
  }
> = ({ onClick, className, disabled, children, type = 'button', leftIcon, rightIcon, ...rest }) => {
  return (
    <button
      {...rest}
      onClick={onClick}
      type={type}
      className={twJoin(
        'box-border flex flex-row items-center justify-center gap-1.5 rounded-2xl border-2 border-primary bg-primary bg-opacity-0 px-6 py-2 font-bold text-primary duration-300 hover:bg-opacity-5 md:text-base',
        disabled ? 'pointer-events-none opacity-50' : null,
        className,
      )}
    >
      {leftIcon}
      <span>{children}</span>
      {rightIcon}
    </button>
  )
}

export default PrimaryButton
