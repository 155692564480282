'use client'
import { GTM_HERO_CREATE_REPLICA_ID } from '@/lib/gtm'
import Image from 'next/image'
import Link from 'next/link'
import { forwardRef } from 'react'

const Hero = forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <section
      ref={ref}
      className="relative w-full -mt-16 bg-[#F9F4E4] pt-16 md:pt-28 md:mt-[-60px] md:px-6 lg:pb-0 overflow-hidden md:bg-hero bg-no-repeat bg-cover bg-center"
    >
      <div className="mx-auto flex flex-col-reverse max-w-6xl md:grid grid-cols-2 pb-24 sm:pb-0">
        <div className="flex flex-col justify-center w-full max-w-xs md:max-w-none mx-auto pb-12 pt-9 md:pt-0 px-3">
          <h1 className="max-w-xl text-[#231B1E] text-4xl lg:text-6xl font-butler">
            A Second Self,
            <br />A Second Team
          </h1>
          <p className="my-6 text-[#A3426C] uppercase font-sourcecodepro max-w-xs leading-6">
            Digital Replicas that work 24/7, so you don't have to
          </p>
          <Link
            href="/me"
            id={GTM_HERO_CREATE_REPLICA_ID}
            className="w-full py-3 text-base md:text-xs lg:text-base hover:opacity-70 sm:max-w-xs px-14 text-[#FFFCF4] bg-[#A3426C] rounded font-sourcecodepro text-center"
          >
            Create your Replica
          </Link>
        </div>
        <div className="w-full flex justify-center">
          <div className="w-full relative">
            <div className="absolute left-0 top-1/2 -translate-y-1/2 md:flex items-center justify-between w-full z-10 hidden">
              <div className="flex flex-col gap-9">
                <Image
                  src="/assets/home/orange-bullet.svg"
                  alt="orange-bullet"
                  height={120}
                  width={100}
                  className="object-contain w-32 lg:w-40"
                />
                <Image
                  src="/assets/home/green-bullet.svg"
                  alt="green-bullet"
                  height={100}
                  width={192}
                  className="object-contain w-32 lg:w-40"
                />
              </div>
              <div className="flex flex-col gap-9">
                <Image
                  src="/assets/home/blue-bullet.svg"
                  alt="blue-bullet"
                  height={120}
                  width={100}
                  className="object-contain w-32 lg:w-40"
                />
                <Image
                  src="/assets/home/purple-bullet.svg"
                  alt="purple-bullet"
                  height={100}
                  width={192}
                  className="object-contain w-32 lg:w-40"
                />
              </div>
            </div>
            <div className="md:grid grid-cols-4">
              <div className="col-span-1" />
              <div className="md:max-w-[277px] col-span-2 w-full mx-auto flex flex-col justify-between bg-hero-gradient relative">
                <div className="flex items-center gap-2 px-3 py-1 lg:p-3">
                  <div className="h-3 w-3 bg-black" />
                  <p className="font-sourcecodepro uppercase text-[10px] lg:text-xs">Digital replica [Lucy]</p>
                </div>
                <div className="grid grid-cols-3 items-end md:block max-w-xl mx-auto">
                  <Image
                    src="/assets/home/girl.png"
                    alt="girl"
                    height={433}
                    width={277}
                    className="w-full h-auto object-contain hidden md:block"
                  />
                  <Image
                    src="/assets/home/girl-mobile.png"
                    alt="girl"
                    height={360}
                    width={360}
                    className="w-full object-contain block md:hidden col-span-2"
                  />
                  <div className="flex flex-col justify-between gap-3 py-6 md:hidden sm:h-full col-span-1 pr-3">
                    <Image
                      src="/assets/home/green-bullet-mobile.svg"
                      alt="green-bullet"
                      height={120}
                      width={100}
                      className="object-contain w-28"
                    />
                    <Image
                      src="/assets/home/blue-bullet-mobile.svg"
                      alt="blue-bullet"
                      height={100}
                      width={192}
                      className="object-contain w-28"
                    />

                    <Image
                      src="/assets/home/orange-bullet-mobile.svg"
                      alt="orange-bullet"
                      height={120}
                      width={100}
                      className="object-contain w-28"
                    />
                    <Image
                      src="/assets/home/purple-bullet-mobile.svg"
                      alt="purple-bullet"
                      height={100}
                      width={192}
                      className="object-contain w-28"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
})

export default Hero
