import type { Frequency, SensayPlan } from '@/app/pricing/[[...slugs]]/stripe-plans'
import type { Replica } from '@/db/models/replicas'
import { sendGTMEvent } from '@next/third-parties/google'

// IDs
export const GTM_HERO_CREATE_REPLICA_ID = 'GTM__hero__create-replica'
export const GTM_HEADER_MY_SENSAY_ID = 'GTM__header__my-sensay'
export const GTM_FOOTER_NEWSLETTER_SIGNUP_ID = 'GTM__footer__newsletter_signup'
export const GTM_CARD_CONTACT_ID = 'GTM__card__contact'
export const GTM_CALLOUT_CREATE_REPLICA_ID = 'GTM__callout__create-replica'
export const GTM_WHITEGLOVE_TALK_TO_SALES_ID = 'GTM__whiteglove__talk-to-sales--professional'
export const GTM_CTA_CREATE_REPLICA_BUTTON_ID = 'GTM__callout__create-replica'

export function getGTMPlanId(gtmId: string) {
  return `GTM__plan__${gtmId}`
}

export function getGTMPlanDetailsId(gtmId: string) {
  return `GTM__plan-details__${gtmId}`
}

// Events
const GTM_REPLICA_CREATED_EVENT = 'GTM-replicaCreated'
const GTM_SUBSCRIPTION_BOUGHT_EVENT = 'GTM-subscriptionBought'

export function GTMTrackReplicaCreated(data: {
  userUuid: string
  replicaUuid: string
  replicaType: Replica['training_type']
  isFirstReplica: boolean
  userReplicaCount: number
}) {
  sendGTMEvent({
    event: GTM_REPLICA_CREATED_EVENT,
    ...data,
  })
}

export function GTMTrackSubscriptionBought(data: {
  plan: SensayPlan
  frequency: Frequency
  price: number
  userUuid: string
}) {
  sendGTMEvent({ event: GTM_SUBSCRIPTION_BOUGHT_EVENT, ...data })
}
