'use client'

import type { Replica as IReplica } from '@/lib/types/supabase'
import { SpeakerHigh } from '@phosphor-icons/react/dist/ssr'
import Image from 'next/image'
import { useRouter } from 'next/navigation'
import { ReplicaFavourite } from './ReplicaFavourite'

type ReplicaProps = {
  replica: IReplica
  children?: React.ReactNode
}

const Replica = ({ replica, children }: ReplicaProps) => {
  const router = useRouter()

  return (
    <div
      onClick={() => router.push(`/${replica.slug}`)}
      className="cursor-pointer relative flex aspect-square items-end justify-center overflow-hidden rounded-3xl shadow-box"
    >
      <div className="absolute left-4 top-4 z-10">{children}</div>

      <ReplicaFavourite replicaSlug={replica.slug} />

      {/* TODO: move into a client component */}
      {/* {isLocked ? (
        <span className="absolute left-6 top-6 z-10 inline-flex cursor-pointer items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
          Premium
        </span>
      ) : null} */}

      <Image
        alt={`Learn from ${replica.name}`}
        src={replica.profile_image}
        className="absolute inset-0 m-auto duration-200 hover:scale-110"
        width={300}
        height={300}
        sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
      />

      <div className="flex w-full flex-col items-center bg-gray-800/60 p-2 backdrop-blur-md">
        <div className="flex max-w-48 items-center gap-1 sm:max-w-52">
          <p className="truncate text-sm text-secondary">{replica.name}</p>
          {replica.elevenlabsId ? <SpeakerHigh className="text-secondary opacity-80" weight="fill" /> : null}
        </div>
        <p
          title={replica.short_description ?? undefined}
          style={{ minWidth: 0 }}
          className="max-w-48 truncate text-xs text-secondary opacity-80 sm:max-w-52"
        >
          {replica.short_description}
        </p>
      </div>
    </div>
  )
}

export default Replica
