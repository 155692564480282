import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/(home)/Featured.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/(home)/HeaderHero.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/Replica.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/decoration-bg-green.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/decoration-bg-pink.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/decoration-bg-yellow.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/star.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/thin-star.svg");
